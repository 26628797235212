<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">Компании</h2>
                    </div>

                    <div class="d-flex">
                        <router-link
                            :to="{name: 'CampaignsCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>


                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchUsers"
                                v-model="search.user"
                                :options="utils.users" 
                                placeholder="Пользователь"
                                label="email">
                                <template v-slot:option="option">
                                    <span>{{ option.email }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.name"
                                type="text" 
                                placeholder="Название" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.type"
                                :options="utils.types"
                                placeholder="Тип компании"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.status"
                                :options="utils.statuses"
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>
                                            <router-link
                                                :to="{name: 'UsersItem', params: {id: row.user_id}}">
                                                {{ row.user_email }}
                                            </router-link>
                                        </td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ utils.types.find(el => el.key == row.type).name }}</td>
                                        <td>
                                            <template v-if="row.status == 0">
                                                <span class="badge badge-casino fs--3 badge-casino-warning">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-regular fa-clock"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 1">
                                                <span class="badge badge-casino fs--3 badge-casino-success">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-check"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 2">
                                                <span class="badge badge-casino fs--3 badge-casino-danger">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 3">
                                                <span class="badge badge-casino fs--3 badge-casino-danger">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>
                                            </template>
                                        </td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>
                                            <button
                                                v-if="1==2"
                                                @click="$modal.show('generate-land'), setCampaign(row), land = null"
                                                content="Лендинг" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                type="button"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <i class="fa-light fa-globe"></i>
                                            </button>
                                            <button
                                                :disabled="row.type == 3"
                                                @click="$modal.show('item-link'), setCampaign(row)"
                                                type="button"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-link"></i>
                                            </button>
                                            <router-link
                                                :to="{name: 'CampaignsItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>
                    </div>
                </div>


                <!-- Modals -->
                <modal 
                    name="item-link"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <div class="row g-3" v-on:submit.prevent="createItem">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="link"
                                    placeholder="Ссылка"
                                    class="form-control" 
                                    type="text"/>
                                <label>Ссылка</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="mirror"
                                    :options="utils.mirrors" 
                                    placeholder="Домен"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Домен</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="lang"
                                    :options="utils.languages" 
                                    placeholder="Язык"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Язык</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLink()"
                                    v-model="page"
                                    :options="utils.pages" 
                                    placeholder="Страница"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Страница</label>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="button" @click="copyLink">
                                <span>Скопировать</span>
                            </button>
                        </div>
                    </div>
                </modal>

                <modal 
                    name="generate-land"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <div class="row g-3">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLand()"
                                    v-model="land"
                                    :options="utils.landers" 
                                    placeholder="Лендинг"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Лендинг</label>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" v-if="land">
                            <div class="form-floating">
                                <input
                                    v-model="link_land"
                                    placeholder="Ссылка"
                                    class="form-control" 
                                    type="text"/>
                                <label>Ссылка</label>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" v-if="land">
                            <div class="form-floating v-select-medium">
                                <vue-select
                                    @input="changeLangLand()"
                                    v-model="lang"
                                    :options="utils.languages" 
                                    placeholder="Язык"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                      </template>
                                    </template>
                                </vue-select>
                                <label>Язык</label>
                            </div>
                        </div>

                        <div class="col-sm-4 col-md-4" v-if="land">
                            <button class="btn btn-primary px-5 w-100 text-nowrap" type="button" @click="copyLinkLand">
                                <span>Скопировать</span>
                            </button>
                        </div>
                    </div>
                </modal>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            status_item_preloader: false,
            selected_campaign: null,
            current_domain: null,
            link: null,
            link_land: null,
            land: null,
            lang: null,
            mirror: null,
            page: {
                name: 'Главная',
                key: 'home'
            },
            columns: [
                {label: 'ID', field: 'id', sortable: true},
                {label: 'Пользователь'},
                {label: 'Название'},
                {label: 'Тип'},
                {label: 'Статус'},
                {label: 'Дата', field: 'created_at', sortable: true}
            ],
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null,
                user: null,
                name: null,
                type: null,
                status: null
            },
            utils: {
                languages: [],
                users: [],
                landers: [],
                mirrors: [],
                pages: [
                    {
                        name: 'Главная',
                        key: 'home'
                    },
                    {
                        name: 'Регистрация',
                        key: 'signup'
                    },
                    {
                        name: 'Авторизация',
                        key: 'login'
                    },
                ],
                types: [
                    {
                        name: 'Revshare',
                        key: 1
                    },
                    {
                        name: 'CPA',
                        key: 2
                    },
                    {
                        name: 'Персональный Промокод',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'Модерация',
                        key: 0
                    },
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 2
                    },
                    {
                        name: 'Отклонена',
                        key: 3
                    },
                ]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/campaigns/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user: this.search.user ? this.search.user.id : null,
                    name: this.search.name,
                    type: this.search.type != null ? this.search.type.key : null,
                    status: this.search.status != null ? this.search.status.key : null
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.current_domain = items.data.domain
                this.land_domain = items.data.land_domain
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        getLanders () {
            this.preloader = true
            this.$axios.post('/settings/landers/items', JSON.stringify({
                search: {
                    name: null,
                    status: 1
                }
            }))
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.landers = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getLanguages() {
            this.$axios.post('/utils/languages/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item,
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        getMirrors() {
            this.$axios.post('/utils/mirrors/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for (let item of response.data) {
                            this.utils.mirrors.push({
                                name: item.domain,
                                key: item.domain,
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        copyLink () {
            navigator.clipboard.writeText(this.link)
            this.$toast.success('Скопировано')
        },
        copyLinkLand () {
            navigator.clipboard.writeText(this.link_land)
            this.$toast.success('Скопировано')
        },
        setCampaign (row) {
            this.selected_campaign = row
            this.link = `https://${this.current_domain}?ref_id=${this.selected_campaign.id}&click_id={SID}`
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        },
        changeLand () {
            if(this.land) {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}&click_id={SID}`
            }
        },
        changeLangLand () {
            if(this.lang) {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}}&lang=${this.lang.key}&click_id={SID}`
            } else {
                this.link_land = `https://${this.land_domain}?land=${this.land.slug}&ref_id=${this.selected_campaign.id}&click_id={SID}`
            }
        },
        changeLink () {
            var params = {
                ref_id: this.selected_campaign.id,
                //click_id: '{SID}'
            }

            if (this.lang) {
                params.lang = this.lang.key
            }

            if (this.mirror) {
                params.domain = this.mirror.key
            }

            if (this.page && this.page.key != 'home') {
                params.page = this.page.key
            }

            this.link = `https://${this.current_domain}?` + new URLSearchParams(params).toString()
        }
    },
    beforeMount () {
        this.getLanguages()
        this.getLanders()
        this.getMirrors()
    }
}
</script>
