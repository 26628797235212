<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Компания {{data.name}}</h2>
                        </div>
                    </div>

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <div class="col-xl-12">
                            <div class="row g-3">

                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating v-select-medium">
                                        <vue-select
                                            disabled
                                            v-model="data.user"
                                            placeholder="Пользователь"
                                            label="email">
                                            <template v-slot:option="option">
                                                <span>{{ option.email }}</span>
                                            </template>
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователь</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            disabled
                                            @input="errors.type = null"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Тип компании"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип компании</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.postback"
                                            @input="errors.postback = null"
                                            :class="{'is-invalid': errors.postback}"
                                            placeholder="Postback"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Postback</label>

                                        <span 
                                            @click="togglePostbackMacro('{CLICK_ID}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{CLICK_ID}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{CLICK_ID}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {CLICK_ID}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{ACTION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{ACTION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{ACTION}')
                                            }"
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            :content="`registration, deposit, withdraw`"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {ACTION}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{AMOUNT}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{AMOUNT}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{AMOUNT}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {AMOUNT}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{REVENUE}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{REVENUE}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{REVENUE}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {REVENUE}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{IP}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{IP}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{IP}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {IP}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{COUNTRY}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{COUNTRY}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{COUNTRY}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {COUNTRY}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{CITY}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{CITY}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{CITY}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {CITY}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{DEVICE}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{DEVICE}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{DEVICE}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {DEVICE}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{BROWSER}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{BROWSER}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{BROWSER}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {BROWSER}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{BROWSER_VERSION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{BROWSER_VERSION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{BROWSER_VERSION}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {BROWSER_VERSION}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{PLATFORM}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{PLATFORM}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{PLATFORM}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {PLATFORM}
                                        </span>
                                        <span 
                                            @click="togglePostbackMacro('{PLATFORM_VERSION}')"
                                            :class="{
                                                'badge-casino-primary': data.postback && data.postback.includes('{PLATFORM_VERSION}'),
                                                'badge-casino-macros': !data.postback || !data.postback.includes('{PLATFORM_VERSION}')
                                            }"
                                            class="badge badge-casino fs--3 badge-macros">
                                            {PLATFORM_VERSION}
                                        </span>

                                        <div class="invalid-feedback">{{errors.postback}}</div>
                                    </div>
                                </div>

                                <div 
                                    :class="{'col-md-6': data.type.key != 1, 'col-md-4': data.type.key == 1}"
                                    class="col-sm-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.hold"
                                            @input="errors.hold = null"
                                            :class="{'is-invalid': errors.hold}"
                                            placeholder="HOLD (дней)"
                                            class="form-control" 
                                            type="text"/>
                                        <label>HOLD (дней)</label>
                                        <div class="invalid-feedback">{{errors.hold}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-4" v-if="data.type.key == 1">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.revshare"
                                            @input="errors.revshare = null"
                                            :class="{'is-invalid': errors.revshare}"
                                            placeholder="Revshare (%)"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Revshare (%)</label>
                                        <div class="invalid-feedback">{{errors.revshare}}</div>
                                    </div>
                                </div>


                                <div class="col-sm-6 col-md-6" v-if="data.type.key == 3">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.revshare"
                                            @input="errors.revshare = null"
                                            :class="{'is-invalid': errors.revshare}"
                                            placeholder="Revshare (%)"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Revshare (%)</label>
                                        <div class="invalid-feedback">{{errors.revshare}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6" v-if="data.type.key == 3">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.promocode"
                                            @input="errors.promocode = null"
                                            :class="{'is-invalid': errors.promocode}"
                                            placeholder="Персональный Промокод"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Персональный Промокод</label>
                                        <div class="invalid-feedback">{{errors.promocode}}</div>
                                    </div>
                                </div>

                                <div 
                                    :class="{'col-md-6': data.type.key != 1, 'col-md-4': data.type.key == 1}"
                                    class="col-sm-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="row g-3" v-if="data.type.key == 2 && data.cpa">

                                    <template v-for="(merchant_data, merchant_index) in data.cpa">

                                        <div class="card-header p-2 d-flex flex-between-center">
                                            <div class="d-flex align-items-center">
                                                <h2 class="fs-1">{{ merchant_data.merchant.name}} ({{ merchant_data.merchant.currency}})</h2>
                                            </div>

                                            <div class="d-flex">
                                                <a 
                                                    @click="newDeposit(merchant_index)"
                                                    class="btn btn-primary me-1 mb-1 float_right">Новый депозит</a>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-12 mb-3">
                                            <div class="form-floating campaign-cpa-raw">
                                                <div 
                                                    class="campaign-cpa-number">
                                                    <span class="vertical-center-item">N</span>
                                                </div>
                                                <input
                                                    v-model="data.cpa[merchant_index].registration.revenue"
                                                    @input="errors.cpa[merchant_index].registration = null"
                                                    :class="{'is-invalid': errors.cpa[merchant_index].registration}"
                                                    placeholder="Регистрация"
                                                    class="form-control campaign-cpa-registration" 
                                                    type="text"/>
                                                <label>Регистрация</label>

                                                <button 
                                                    disabled
                                                    type="button" 
                                                    class="btn btn-soft-danger tournament-button-right">
                                                    <span class="vertical-center-item">
                                                        <i class="fa-regular fa-trash"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="invalid-feedback">{{errors.cpa[merchant_index].registration}}</div>
                                        </div>

                                        <template v-for="(item, index) in merchant_data.deposits" v-if="refresh_merchant_data">
                                            <div class="col-sm-6 col-md-4">
                                                <div class="form-floating campaign-cpa-raw">
                                                    <div 
                                                        content="№ депозита" 
                                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                                        class="campaign-cpa-number">
                                                        <span class="vertical-center-item">{{index + 1}}</span>
                                                    </div>
                                                    <input
                                                        v-model="data.cpa[merchant_index].deposits[index].revenue"
                                                        @input="errors.cpa[merchant_index].deposits[index].revenue = null"
                                                        :class="{'is-invalid': errors.cpa[merchant_index].deposits[index].revenue}"
                                                        placeholder="Выплата"
                                                        class="form-control" 
                                                        type="text"/>
                                                    <label>Значение</label>
                                                </div>
                                                <div class="invalid-feedback">{{errors.cpa[merchant_index].deposits[index].revenue}}</div>
                                            </div>

                                            <div class="col-sm-6 col-md-4">
                                                <div class="form-floating">
                                                    
                                                    <input
                                                        v-model="data.cpa[merchant_index].deposits[index].min_deposit"
                                                        @input="errors.cpa[merchant_index].deposits[index].min_deposit = null"
                                                        :class="{'is-invalid': errors.cpa[merchant_index].deposits[index].min_deposit}"
                                                        placeholder="Мин. депозит"
                                                        class="form-control" 
                                                        type="text"/>
                                                    <label>Мин. депозит</label>
                                                </div>
                                                <div class="invalid-feedback">{{errors.cpa[merchant_index].deposits[index].min_deposit}}</div>
                                            </div>

                                            <div class="col-sm-6 col-md-4">
                                                <div class="form-floating campaign-cpa-raw">
                                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.cpa[merchant_index].deposits[index].type}">
                                                        <vue-select
                                                            @input="errors.cpa[merchant_index].deposits[index].type = null"
                                                            v-model="data.cpa[merchant_index].deposits[index].type"
                                                            :options="utils.cpa_types" 
                                                            placeholder="Тип"
                                                            label="name">
                                                            <template v-slot:no-options="{ search, searching }">
                                                              <template v-if="searching">
                                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                                              </template>
                                                            </template>
                                                        </vue-select>
                                                        <label>Тип</label>
                                                        <div class="invalid-feedback">{{errors.cpa[merchant_index].deposits[index].type}}</div>
                                                    </div>

                                                    <button 
                                                        @click="deleteDeposit(merchant_index, index)"
                                                        content="Удалить" 
                                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                                        type="button" 
                                                        class="btn btn-soft-danger tournament-button-right">
                                                        <span class="vertical-center-item">
                                                            <i class="fa-regular fa-trash"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>


                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            disabled
                                            v-model="data.traffic_source"
                                            placeholder="Источник трафика"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Источник трафика</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <textarea
                                            disabled
                                            v-model="data.comment"
                                            placeholder="Доп. Информация"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Доп. Информация</label>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            refresh_merchant_data: String(Date.now()),
            data: {
                name: null,
                type: null,
                hold: null,
                revshare: null,
                cpa: null,
                promocode: null,
                traffic_source: null,
                comment: null,
            },
            errors: {
                name: false,
                type: false,
                hold: null,
                revshare: null,
                cpa: [],
                promocode: null
            },
            utils: {
                merchants: [],
                types: [
                    {
                        name: 'Revshare',
                        key: 1
                    },
                    {
                        name: 'CPA',
                        key: 2
                    },
                    {
                        name: 'Персональный Промокод',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'Модерация',
                        key: 0
                    },
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 2
                    },
                    {
                        name: 'Отклонена',
                        key: 3
                    },
                ],
                cpa_types: [
                    {
                        name: 'CPA ($)',
                        key: 'cpa'
                    },
                    {
                        name: 'Revshare (%)',
                        key: 'revshare'
                    }
                ],
                macros: {
                    click_id: '{CLICK_ID}',
                    action: '{ACTION}',
                    amount: '{AMOUNT}',
                    revenue: '{REVENUE}',
                    ip: '{IP}',
                    country: '{COUNTRY}',
                    city: '{CITY}',
                    device: '{DEVICE}',
                    browser: '{BROWSER}',
                    browser_version: '{BROWSER_VERSION}',
                    platform: '{PLATFORM}',
                    platform_version: '{PLATFORM_VERSION}'
                }
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/campaigns/item/${this.$props.id}`)
            .then(async (response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data = response.data
                        this.data.type = this.utils.types.find(row => row.key == this.data.type)
                        this.data.status = this.utils.statuses.find(row => row.key == this.data.status)
                        this.data.user = {
                            email: this.data.user_email,
                            id: this.data.user_id
                        }

                        if(this.data.type.key == 2) {
                            await this.getMerchants()

                            var cpa = this.data.cpa
                            this.data.cpa = []

                            if(!cpa) {
                                
                                for (var item of this.utils.merchants) {
                                    this.data.cpa.push({
                                        merchant: {
                                            name: item.name,
                                            currency: item.currency
                                        },
                                        registration: {
                                            revenue: 0
                                        },
                                        deposits: [
                                            {
                                                revenue: 0,
                                                min_deposit: 0,
                                                type: this.utils.cpa_types[0]
                                            }
                                        ]
                                    })

                                    this.errors.cpa.push({
                                        registration: null,
                                        deposits: [{
                                            revenue: null,
                                            min_deposit: null,
                                            type: null
                                        }]
                                    })
                                }
                            } else {
                                for (var item in this.utils.merchants) {

                                    var merchant = this.utils.merchants[item]

                                    this.errors.cpa[item] = {
                                        registration: null,
                                        deposits: []
                                    }

                                    if(merchant.currency in cpa) {

                                        var deposits = cpa[merchant.currency].deposits

                                        for (let dep_item in deposits) {
                                            deposits[dep_item].type = this.utils.cpa_types.find(row => row.key == deposits[dep_item].type)

                                            this.errors.cpa[item].deposits.push({
                                                revenue: null,
                                                min_deposit: null,
                                                type: null
                                            })
                                        }

                                        this.data.cpa.push({
                                            merchant: {
                                                name: merchant.name,
                                                currency: merchant.currency
                                            },
                                            registration: {
                                                revenue: cpa[merchant.currency].registration.revenue
                                            },
                                            deposits: deposits
                                        })

                                        this.errors.cpa.push({
                                            registration: null,
                                            deposits: [{
                                                revenue: null,
                                                min_deposit: null,
                                                type: null
                                            }]
                                        })
                                    } else {
                                        this.data.cpa.push({
                                            merchant: {
                                                name: merchant.name,
                                                currency: merchant.currency
                                            },
                                            registration: {
                                                revenue: 0
                                            },
                                            deposits: [
                                                {
                                                    revenue: 0,
                                                    min_deposit: 0,
                                                    type: this.utils.cpa_types[0]
                                                }
                                            ]
                                        })

                                        this.errors.cpa[item].deposits.push({
                                            revenue: null,
                                            min_deposit: null,
                                            type: null
                                        })
                                    }
                                }
                            }
                        }

                        this.preloader = false

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'CampaignsItems'})
                }
            })
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(this.data.postback) {
                if(!this.isValidUrl(this.data.postback)) {
                    this.errors.postback = 'Недействительный Postback URL'
                    return
                }
            }
            if(!this.data.hold) {
                this.errors.hold = 'Это поле обязательно'
                return
            }
            if(this.data.type.key == 1) {
                if(!this.data.revshare) {
                    this.errors.revshare = 'Это поле обязательно'
                    return
                }
            }
            if(this.data.type.key == 2) {

                for (let merchant_index in this.data.cpa) {
                    if(isNaN(this.data.cpa[merchant_index].registration.revenue)) {
                        this.errors.cpa[merchant_index].registration = 'Это поле должно быть числом'
                        return
                    }
                }

                var th = this, checked = false
                for (let merchant_index in this.data.cpa) {
                    this.data.cpa[merchant_index].deposits.forEach((item, index) => {
                        if(isNaN(item.revenue)) {
                            th.errors.cpa[merchant_index].deposits[index].revenue = 'Это поле должно быть числом'
                            checked = true
                            th.refreshMerchantData()
                            return
                        }
                        if(isNaN(item.min_deposit)) {
                            th.errors.cpa[merchant_index].deposits[index].min_deposit = 'Это поле должно быть числом'
                            checked = true
                            th.refreshMerchantData()
                            return
                        }
                        if(!item.type) {
                            th.errors.cpa[merchant_index].deposits[index].type = 'Это поле обязательно'
                            checked = true
                            th.refreshMerchantData()
                            return
                        }
                    })
                }

                if(checked) {
                    return
                }
            }
            if(this.data.type.key == 3) {
                if(!this.data.revshare) {
                    this.errors.revshare = 'Это поле обязательно'
                    return
                }
                if(!this.data.promocode) {
                    this.errors.promocode = 'Это поле обязательно'
                    return
                }
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }

            if(this.data.type.key == 2) {
                for (let merchant_item in this.data.cpa) {
                    for (let item in this.data.cpa[merchant_item].deposits) {
                        this.data.cpa[merchant_item].deposits[item].type = this.data.cpa[merchant_item].deposits[item].type.key
                    }
                }
            }

            this.btn_preloader = true
            this.$axios.post('/campaigns/update', JSON.stringify({
                id: this.$props.id,
                name: this.data.name,
                postback: this.data.postback,
                hold: this.data.hold,
                revshare: this.data.revshare,
                cpa: this.data.cpa,
                promocode: this.data.promocode,
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                        this.refreshMerchantData()
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getMerchants () {
            await this.$axios.post(`/merchants/items`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchants = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'UsersStatusItems'})
                }
            })
        },
        newDeposit (merchant_index) {
            this.data.cpa[merchant_index].deposits.push({
                revenue: 0,
                min_deposit: 0,
                type: this.utils.cpa_types[0]
            })

            this.errors.cpa[merchant_index].deposits.push({
                revenue: null,
                min_deposit: null,
                type: null
            })
        },
        deleteDeposit (merchant_index, index) {
            if(this.data.cpa[merchant_index].deposits.length > 1) {
                this.data.cpa[merchant_index].deposits.splice(index, 1)
            }
        },
        togglePostbackMacro (item) {
            if(this.data.postback && this.isValidUrl(this.data.postback)) {
                const parseUrl = new URL(this.data.postback)
                
                var params = new URLSearchParams(parseUrl.search.substring(parseUrl.search.indexOf('?')))

                if(this.data.postback.includes(item)) {
                    for(var target_item in this.utils.macros) {
                        if(this.utils.macros[target_item] == item) {
                            params.delete(target_item)
                            break
                        }
                    }
                } else {
                    for(var target_item in this.utils.macros) {
                        if(this.utils.macros[target_item] == item) {
                            params.append(target_item, item)
                            break
                        }
                    }
                }

                var url = `${parseUrl.protocol}//${parseUrl.hostname}`

                if(parseUrl.port) {
                    url += ':' + parseUrl.port
                }

                if(parseUrl.pathname) {
                    url += parseUrl.pathname
                }


                if(params.size) {
                    var query = params.toString()
                    query = query.replace(/%7B/g, '{')
                    query = query.replace(/%7D/g, '}')

                    this.data.postback = `${url}?${query}`
                } else {
                    this.data.postback = url
                }
            } else {
                this.errors.postback = 'Недействительный Postback URL'
            }
        },
        isValidUrl (string) {
            try {
                new URL(string)
            } catch (_) {
                return false
            }

            return true
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        refreshMerchantData () {
            this.refresh_merchant_data = String(Date.now())
        }
    },
    beforeMount() {
        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
