export default {
  ApplicationNAME: 'Admin',
  ApplicationAPI: 'https://api.affiliate-pure-gold.casino/api/v1/admin',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '',
    action: 'affiliate'
  },
  RecaptchaV2: {
    token: ''
  },
  Timezone: 'Europe/Moscow'
}
